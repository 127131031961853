<template>
    <div class="newMess-wrapper">
        <div class="content">
            <el-breadcrumb separator=">">
                <el-breadcrumb-item :to="{ path: '/notificationPushList', query: { appid: appid, nickName: nickName, headImg: headImg } }">模板消息推送</el-breadcrumb-item>
                <el-breadcrumb-item>新增模板消息</el-breadcrumb-item>
            </el-breadcrumb>

            <div class="ent-wrap">
                <div class="mess-item">
                    <div class="mess-name">消息名称</div>
                    <div class="mess-inp">
                        <el-input type="text" v-model="messName" maxlength="30" show-word-limit
                         placeholder="请输入名称，仅运营人员自己可见" clearable />
                    </div>
                </div>
                <div class="mess-item timed-wrap">
                    <div class="mess-name">推送时间</div>

                    <div class="timed-item">
                        <div class="mess-radio mess-i">
                            <el-radio-group v-model="radioPush">
                                <el-radio :label="1">即时推送</el-radio>
                                <el-radio :label="2">定时推送</el-radio>
                                <el-radio :label="3">每日推送</el-radio>
                                <el-radio :label="4">每周推送</el-radio>
                            </el-radio-group>
                        </div>

                        <div class="timed-push" v-if="radioPush == 2">
                            <el-date-picker v-model="datetimeVal" type="datetime" clearable
                             placeholder="选择日期时间" default-time="12:00:00" value-format="yyyy-MM-dd HH:mm:ss"
                             @change="changePush"></el-date-picker>
                        </div>

                        <div class="timed-push" v-if="radioPush == 3">
                            <el-time-picker v-model="timeVal" value-format="HH:mm:ss"
                             placeholder="请设置具体的推送时间" @change="changeDaily">
                            </el-time-picker>
                        </div>

                        <div class="timed-push-item" v-if="radioPush == 4">
                            <el-checkbox-group v-model="checkedCities">
                                <el-checkbox v-for="(item, index) in cities" :label="item.id" :key="item.id"
                                 @change="changeCheckbox($event, item.id, index)">{{item.name}}</el-checkbox>
                            </el-checkbox-group>

                            <div class="radioval-item">
                                <el-time-picker v-model="radioPushVal" value-format="HH:mm:ss"
                                 placeholder="请设置具体的推送时间" @change="changeWeek">
                                </el-time-picker>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mess-item timed-wrap">
                    <div class="mess-name">推送对象</div>

                    <div class="timed-item">
                        <div class="mess-radio">
                            <el-radio-group v-model="radioFans">
                                <el-radio :label="1">全部粉丝</el-radio>
                                <el-radio :label="2">粉丝标签</el-radio>
                            </el-radio-group>
                        </div>

                        <div class="mess-select" v-if="radioFans == 2" style="margin-top: 15px;">
                            <el-select v-model="fansId" clearable
                             multiple collapse-tags
                             filterable allow-create default-first-option
                             placeholder="请选择标签" @change="selectFans">
                                <el-option v-for="item in tagsOptions" :key="item.name"
                                 :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="mess-item timed-wrap">
                    <div class="mess-name">活跃用户</div>

                    <div class="active-user">
                        <div class="mess-radio">
                            <el-radio-group v-model="radioActive">
                                <el-radio :label="0">不限</el-radio>
                                <el-radio :label="1">活跃</el-radio>
                                <el-radio :label="2">不活跃</el-radio>
                            </el-radio-group>
                        </div>
                        <div class="user-item" v-if="radioActive == 1 || radioActive == 2">
                            <div class="user-text">近</div>
                            <div class="user-inp">
                                <el-input type="text" v-model="numberActive" placeholder="请输入" />
                            </div>
                            <div class="user-text" v-if="radioActive == 1">日活跃</div>
                            <div class="user-text" v-if="radioActive == 2">日不活跃</div>
                            <div class="user-texts">(仅支持输入1-60天整数)</div>
                        </div>
                    </div>
                </div>

                <div class="mess-item">
                    <div class="mess-name">排除对象</div>

                    <div class="mess-select">
                        <el-select v-model="objectId" clearable
                         multiple collapse-tags
                         filterable allow-create default-first-option
                         placeholder="不设置" @change="selectObj">
                            <el-option v-for="item in tagsOptions" :key="item.name"
                             :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="mess-item timed-wrap">
                    <div class="mess-name">消息模板</div>

                    <div>
                        <div class="mess-select">
                            <el-select v-model="templateName"
                             filterable allow-create default-first-option
                             placeholder="请选择模板" @change="selectTemp">
                                <el-option v-for="item in templatOptions" :key="item.title"
                                 :label="item.title" :value="item"></el-option>
                            </el-select>
                        </div>

                        <div class="tips"> 如需添加新的消息模板，请前往 
                            <a href="https://mp.weixin.qq.com/" target="_blank">微信公众平台</a>
                        </div>

                        <div class="template-wrap" v-for="(item, index) in templateArr" :key="index">
                            <div class="template-item" v-for="(items, indexs) in item" :key="indexs">
                                <template v-if="items.is_input == 'F'">
                                    <div class="template-name">{{items.name}}</div>
                                </template>
                                <div class="template-name" v-if="item.length == 1"></div>

                                <div class="textarea-item" v-if="items.is_input == 'T'">
                                    <el-input type="textarea" autosize @input="textChange($event)"
                                     placeholder="请输入内容" v-model="items.textVal">
                                    </el-input>
                                </div>

                                <div class="color-item" v-if="items.is_input == 'T'">
                                    <el-color-picker v-model="colorObj['val' + index]"
                                     size="small" @change="changeColor($event, index)"></el-color-picker>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                
                <div class="mess-item timed-wrap">
                    <div class="mess-name">跳转链接</div>

                    <div class="link-box">
                        <div class="mess-radio">
                            <el-radio-group v-model="radioLink">
                                <el-radio :label="1">小程序</el-radio>
                                <el-radio :label="2">链接</el-radio>
                                <el-radio :label="0">无</el-radio>
                            </el-radio-group>
                        </div>

                        <div class="refresh-item" v-if="radioLink == 1">
                            <div class="refresh-appid">
                                <div class="appid-name">小程序AppID:</div>

                                <el-select v-model="cardAppid"
                                 filterable allow-create default-first-option
                                 placeholder="请输入小程序AppID或绑定后快捷选择"
                                 @change="selectMarket">
                                    <el-option v-for="item in wxOptions" :key="item.nickname"
                                     :label="item.nickname" :value="item.appid">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="refresh-inp">
                                <el-input type="text" v-model="m_pagepath" placeholder="请输入跳转小程序页面 示例：pages/index/index"
                                 clearable>
                                    <template #prepend>小程序路径:</template>
                                </el-input>
                            </div>
                        </div>
                        <div class="refresh-item" v-if="radioLink == 2">
                            <el-input type="text" v-model="link_url" placeholder="请输入链接" clearable>
                                <template #prepend>页面地址：</template>
                            </el-input>
                        </div>
                    </div>
                </div>

            </div>

            <div class="btnsItem">
                <el-button type="danger" :disabled="disabled" @click="save">发送</el-button>
                
                <el-button @click="cancel">取消</el-button>
            </div>

        </div>
        
    </div>
</template>

<script>
    import axios from 'axios';
    import global from '@/assets/js/global';
    export default {
        data() {
            return {
                appid: this.$route.query.appid,
                nickName: this.$route.query.nickName,
                headImg: this.$route.query.headImg,
                messName: '',
                radioPush: 1,
                checkedCities: [],
                cities: [
                    {id: 1, name: '周一'},
                    {id: 2, name: '周二'},
                    {id: 3, name: '周三'},
                    {id: 4, name: '周四'},
                    {id: 5, name: '周五'},
                    {id: 6, name: '周六'},
                    {id: 7, name: '周日'}
                ],
                weekData: [],
                radioPushVal: '',

                radioFans: 1,
                fansId: [],
                tagsOptions: [],

                radioActive: 0,
                numberActive: '',

                objectId: [],
                templateName: '',
                templateId: '',
                templatOptions: [],
                templateArr: [],
                datetimeVal: '',
                timeVal: '',

                radioLink: 0,
                wxOptions: [],
                cardAppid: '',
                m_pagepath: '',
                link_url: '',

                disabled: false,
                color: '#252530',
                colorObj: {},
                templateValueJson: {}

            }
        },
        mounted() {
            // 关联的小程序appid列表
            this.getWxamplink();
            // 公众号标签
            this.getTagSetting();
            // 获取固定模板列表
            this.getTemplatSetting();

            var contItem = this.$route.query.item;
            if(contItem) {
                // console.log(contItem)
                this.messName = contItem.name;
                this.radioPush = contItem.send_type;
                if(this.radioPush == 2) {
                    this.datetimeVal = contItem.send_type_json.stime;
                } else if(this.radioPush == 3) {
                    this.timeVal = contItem.send_type_json.stime;
                } else if(this.radioPush == 4) {
                    this.radioPushVal = contItem.send_type_json.stime;
                }
                for(var i = 0; i < contItem.send_type_json.week.length; i++) {
                    this.checkedCities.push(contItem.send_type_json.week[i]);
                    this.weekData = this.checkedCities;
                }
                this.radioFans = contItem.push_fans_type;
                this.fansId = contItem.fans_tags;
                this.radioActive = contItem.is_active;
                this.numberActive = contItem.active_user_day;
                this.objectId = contItem.exclude_fans_tags;
                this.templateName = contItem.template_name;
                this.templateId = contItem.template_id;

                this.templateValueJson = contItem.template_value_json;
                
                this.radioLink = contItem.jump_link_type;
                this.cardAppid = contItem.jump_link_json.appid;
                this.m_pagepath = contItem.jump_link_json.pagepath;
                this.link_url = contItem.jump_link_json.url;

            }
        },
        methods: {
            // 关联的小程序appid列表
            getWxamplink() {
                axios.get(global.wechatURL + "third/wxamplinkget", {
                    params: {
                        appid: this.appid
                    }
                }).then(res => {
                    if(res.data.status == 200) {
                        var data = res.data.data;
                        this.wxOptions = data;
                    } else {
                        this.$message({ showClose: true, message: res.data.message, type: 'error'})
                    }
                });
            },
            // 公众号标签
            getTagSetting() {
                axios.get(global.wechatURL + "third/tag_setting", {
                    params: {
                        appid: this.appid
                    }
                }).then(res => {
                    if(res.data.status == 200) {
                        var data = res.data.data.tags;
                        this.tagsOptions = data;
                    } else {
                        this.$message({ showClose: true, message: res.data.message, type: 'error'})
                    }
                });
            },
            // 获取固定模板列表
            getTemplatSetting() {
                var that = this;
                axios.get(global.wechatURL + "third/templat_setting", {
                    params: {
                        appid: that.appid
                    }
                }).then(res => {
                    if(res.data.status == 200) {
                        var data = res.data.data;
                        that.templatOptions = data;
                        if(that.templateId) {
                            that.textChange();
                            that.templatOptions.filter(item => {
                                if (item.title.includes(that.templateName)) {
                                    that.templateArr = item.return_arr;
                                    return item
                                }
                            })
                            
                            that.templateArr.forEach((item, index) => {
                                item.forEach(items => {
                                    if(items.is_input == 'T') {
                                        items.first = true;
                                        for(let i in that.templateValueJson) {
                                            if(items.name == i) {
                                                items.textVal = that.templateValueJson[i].value;
                                                items.color = that.templateValueJson[i].color;
                                                that.$set(that.colorObj, 'val' + index, items.color);
                                            }
                                        }
                                    }
                                })
                            })
                        }
                    } else {
                        that.$message({ showClose: true, message: res.data.message, type: 'error' })
                    }
                });
            },
            // el-input 强制刷新
            textChange() {
                this.$forceUpdate();
            },
            // 选择定时推送的时间
            changePush(e) {
                this.datetimeVal = e;
                // console.log(this.datetimeVal)
            },
            // 选择每日推送的时间
            changeDaily(e) {
                // console.log(e)
                this.timeVal = e;
            },
            // 选择每周时间
            changeCheckbox(event, id, index) {
                // console.log(event, id, index)
                if(event == true) {
                    this.weekData.push(id);
                } else {
                    this.weekData.splice(this.weekData.indexOf(this.weekData[index]), 1);
                }
                // console.log(this.weekData)
            },
            // 选择每周推送的时间
            changeWeek(e) {
                this.radioPushVal = e;
                // console.log(this.radioPushVal)
            },
            // 粉丝标签
            selectFans(e) {
                this.fansId = e;
            },
            selectObj(e) {
                this.objectId = e;
            },
            selectTemp(e) {
                var that = this;

                that.templateValueJson = {};
                that.templateName = e.title;
                that.templateId = e.template_id;
                that.templateArr = e.return_arr;
                that.templateArr.forEach((item, index) => {
                    item.forEach(items => {
                        if(items.is_input == 'T') {
                            items.first = false;
                            items.color = that.color;
                            that.$set(that.colorObj, 'val' + index, items.color);
                        }
                    })
                })
            },
            // 获取颜色值
            changeColor(color, index) {
                this.templateArr[index].color = color;
                // console.log(this.templateArr[index].color)
            },
            selectMarket(e) {
                this.cardAppid = e;
            },

            // 保存
            save() {
                var that = this;

                if(!that.verdict()) { return }

                that.disabled = true;
                
                that.templateArr.forEach((item, index) => {
                    item.forEach(items => {
                        if(items.is_input == 'T') {
                            // console.log(item, '1')
                            var textValue = items.textVal == undefined ? '' : items.textVal;
                            var colorVal = that.templateArr[index].color == undefined ? items.color : that.templateArr[index].color;
                            var n = {
                                value: textValue,
                                color: colorVal
                            }
                            that.$set(that.templateValueJson, items.name, n);
                        }
                    })
                })
                for(let i in that.templateValueJson) {
                    if(!that.templateValueJson[i].value) {
                        that.$message({
                            message: '请完善模板内容!',
                            type: 'warning'
                        })
                        return false
                    }
                }

                var stime
                if(that.radioPush == 2) {
                    stime = that.datetimeVal;
                } else if(that.radioPush == 3) {
                    stime = that.timeVal;
                } else if(that.radioPush == 4) {
                    stime = that.radioPushVal;
                } else {
                    stime = '';
                }

                axios.post(global.wechatURL + "third/templat_setting", {
                    appid: that.appid,
                    name: that.messName,
                    send_type: that.radioPush,
                    send_type_json: {
                        stime,
                        week: that.weekData
                    },
                    push_fans_type: that.radioFans,
                    fans_tags: that.fansId,
                    is_active: that.radioActive,
                    active_user_day: that.numberActive == '' ? that.numberActive = 0 : that.numberActive,
                    exclude_fans_tags: that.objectId,
                    template_id: that.templateId,
                    template_value_json: that.templateValueJson,
                    jump_link_type: that.radioLink,
                    jump_link_json: {
                        appid: that.cardAppid,
                        pagepath: that.m_pagepath,
                        url: that.link_url
                    }
                }).then(res => {
                    if(res.data.status == 200) {
                        if(that.radioPush == 1) {
                            that.$message({
                                message: '消息已发送成功!',
                                type: 'warning'
                            })
                        } else {
                            that.$message({
                                message: '消息已保存!',
                                type: 'warning'
                            })
                        }
                        that.$router.go(-1);
                    } else {
                        that.$message({ showClose: true, message: '发送失败!', type: 'error'});
                        that.disabled = false;
                    }
                });
            },
            // 取消
            cancel() {
                this.$router.go(-1);
            },
            // 判断
            verdict() {
                if(this.messName == '') {
                    this.$message({
                        message: '请输入消息名称!',
                        type: 'warning'
                    })
                    return false;
                } else if(this.radioPush == 2 && (this.datetimeVal == '' || this.datetimeVal == null)) {
                    this.$message({
                        message: '请选择定时推送日期时间!',
                        type: 'warning'
                    })
                    return false;
                } else if(this.radioPush == 3 && (this.timeVal == '' || this.timeVal == null)) {
                    this.$message({
                        message: '请选择每日推送日期时间!',
                        type: 'warning'
                    })
                    return false;
                } else if(this.radioPush == 4 && this.weekData.length == 0) {
                    this.$message({
                        message: '请选择每周推送日期时间!',
                        type: 'warning'
                    })
                    return false;
                } else if(this.radioPush == 4 && (this.radioPushVal == '' || this.radioPushVal == null)) {
                    this.$message({
                        message: '请选择每周推送具体的推送时间!',
                        type: 'warning'
                    })
                    return false;
                } else if(this.radioFans == 2 && this.fansId.length == 0) {
                    this.$message({
                        message: '请选择粉丝标签!',
                        type: 'warning'
                    })
                    return false;
                } else if((this.radioActive == 1 || this.radioActive == 2) && this.numberActive == '') {
                    this.$message({
                        message: this.radioActive == 1 ? '请输入活跃天数!' : '请输入不活跃天数!',
                        type: 'warning'
                    })
                    return false;
                } else if(this.templateName == '') {
                    this.$message({
                        message: '请选择消息模板!',
                        type: 'warning'
                    })
                    return false;
                } else if(this.radioLink == 1) {
                    if(this.cardAppid == '') {
                        this.$message({
                            message: '请输入小程序AppID或绑定后快捷选择!',
                            type: 'warning'
                        })
                        return false;
                    } else if(this.m_pagepath == '') {
                        this.$message({
                            message: '请输入跳转小程序页面!',
                            type: 'warning'
                        })
                        return false;
                    } else {
                        return true;
                    }
                } else if(this.radioLink == 2) {
                    let reg = /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
                    if(!reg.test(this.link_url)) {
                        this.$message({
                            message: '请输入正确格式的链接地址',
                            type: 'warning'
                        })
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            },

        }


    }
</script>

<style lang="less" scoped>
    .content {
        padding: 0 25px;
    }

    .ent-wrap {
        padding: 25px 15px;
    }
    .mess-item {
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        .mess-name {
            width: 95px;
            font-size: 14px;
            color: #252530;
        }
        .mess-inp {
            width: 485px;
        }
        .mess-radio {
            
            /deep/ .el-radio-group {
                display: flex;
                align-items: center;
            }
            /deep/ .el-radio {
                display: flex;
                align-items: center;
            }
            /deep/ .el-radio__inner {
                width: 20px;
                height: 20px;
            }
            /deep/ .el-radio__inner::after {
                width: 8px;
                height: 8px;
            }
            /deep/ .el-radio__input.is-checked .el-radio__inner {
                border-color: #252530;
                background: #252530;
            }
            /deep/ .el-radio__input.is-checked+.el-radio__label {
                color: #252530;
            }
        }
        .timed-push,.timed-push-item {
            margin-top: 15px;
        }
        .mess-select {
            width: 485px;
            /deep/ .el-select {
                width: 100%;
            }
        }
        .timed-push-item {
            padding: 15px 10px;
            background: #f6f6f7;
            .radioval-item {
                margin-top: 20px;
            }
        }

        .refresh-item {
            margin-top: 15px;
            width: 485px;
            .refresh-appid {
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                /deep/ .el-select {
                    width: 100%;
                }
                /deep/ .el-input__inner {
                    border-radius: 0 4px 4px 0;
                }
                .appid-name {
                    -width: 130px;
                    height: 38px;
                    background-color: #F5F7FA;
                    color: #909399;
                    font-size: 14px;
                    line-height: 38px;
                    vertical-align: middle;
                    display: table-cell;
                    position: relative;
                    border: 1px solid #DCDFE6;
                    border-radius: 4px 0 0 4px;
                    padding: 0 20px;
                    white-space: nowrap;
                    border-right: 0;
                }
            }
            .refresh-inp {
                /deep/ .el-input-group__prepend {
                    width: 88px;
                }
            }
        }
    }
    .timed-wrap {
        align-items: flex-start;
    }

    .tips {
        width: fit-content;
        height: 1.872vw;
        line-height: 1.872vw;
        padding-left: 1.04vw;
        padding-right: 1.04vw;
        font-size: .728vw;
        color: #333;
        margin-top: 0.52vw;
        background-color: #f6f6f7;
        a {
            text-decoration: none;
            color: #FF6056;
        }
    }

    .user-item {
        margin-top: 15px;
        display: flex;
        align-items: center;
        .user-text {
            font-size: 14px;
            color: #252530;
        }
        .user-texts {
            margin-left: 5px;
            font-size: 14px;
            color: #FF6056;
        }
        .user-inp {
            margin: 0 10px;
            width: 100px;
            height: 30px;
            /deep/ .el-input__inner {
                height: 30px;
            }
        }
    }

    .template-wrap {
        margin-top: 15px;
        display: flex;
        align-items: center;
        .template-item {
            display: flex;
            align-items: center;
            .template-name {
                margin-right: 10px;
                width: 75px;
                font-size: 14px;
                color: #252530;
                line-height: 33px;
            }
            .textarea-item {
                margin-right: 15px;
                width: 265px;
            }
        }
    }
    
    .btnsItem {
        padding: 35px 0 75px 375px;
    }

</style>